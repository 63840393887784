import { FormGroup, Label, Input } from "reactstrap";

function YesNoDropdown(props) {
  return (
    <FormGroup>
      <Label for={props.name}>{props.title}</Label>
      <Input
        value={props.value}
        onChange={(e) => props.setValue(e.target.value)}
        required
        type="select"
        name={props.name}
        id={props.name}
        placeholder={props.title}
      >
        <option>Please Select</option>
        <option>Yes</option>
        <option>No</option>
      </Input>
    </FormGroup>
  );
}

export default YesNoDropdown;
