import { Container, Row, Col } from "reactstrap";

import logoA from '../Media/EllisLogo.png';
import logoB from '../Media/AvantLogo.png';

function Header() {
    return (
        <>
            <Container>
                <Row>
                    <Col className="titleBlock">Kitchen Call Off Form</Col>
                    <Col>
                        <img src={logoA} className="App-logo" alt="Ellis Logo" />
                        <img src={logoB} className="App-logo" alt="Avant Logo" />
                    </Col>
                </Row>
            </Container> 
        </>
    );
  }
  
  export default Header;
  