import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import YesNoDropdown from "./YesNoDropdown";
import DishwasherDropdown from "./DishwasherDropdown";

//TODO: Refactor

function MainForm(props) {

  //Optionsets
  const [regions, setRegions] = useState([]);
  const [sites, setSites] = useState([]);
  const [kitchenTypes, setKitchenTypes] = useState([]);
  const [houseTypes, setHouseTypes] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [handles, setHandles] = useState([]);
  const [kitchenColours, setKitchenColours] = useState([]);
  const [worktopTypes, setWorktopTypes] = useState([]);
  const [worktopColours, setWorktopColours] = useState([]);

  const [pricingBase, setPricingBase] = useState([]);
  const [pricingUpgrades, setPricingUpgrades] = useState([]);
  const [pricingMatrix, setPricingMatrix] = useState([]);
  const [formError, setFormError] = useState("");

  //Site and Plot Details
  const [orderNumber, setOrderNumber] = useState("");
  const [region, setRegion] = useState(props.loginInfo.region.trim());
  const [deliveryDate, setDeliveryDate] = useState("");
  const [site, setSite] = useState(props.loginInfo.site.trim());
  const [siteContactName, setSiteContactName] = useState(
    props.loginInfo.contactName
  );
  const [siteEmail, setSiteEmail] = useState(props.loginInfo.siteEmail.trim());
  const [siteContactNumber, setSiteContactNumber] = useState(
    props.loginInfo.siteNumber
  );
  const [showHomePlot, setShowHomePlot] = useState("");
  const [plotNumber, setPlotNumber] = useState("");

  //Kitchen Details
  const [kitchenType, setKitchenType] = useState("");
  const [houseType, setHouseType] = useState("");
  const [handling, setHandling] = useState("");
  const [standardUpgrade, setStandardUpgrade] = useState("");
  const [range, setRange] = useState("");
  const [handle, setHandle] = useState("");
  const [kitchenColour, setKitchenColour] = useState("");
  const [worktopType, setWorktopType] = useState("");
  const [worktopColour, setWorktopColour] = useState("");

  var matchingPricingMatrix = null;

  //Appliances
  const [ovenHobHoodPack, setOvenHobHoodPack] = useState("");
  const [integratedFridgeFreezer, setIntegratedFridgeFreezer] = useState("");
  const [tallOvenAndMicrowave, setTallOvenAndMicrowave] = useState("");
  const [sixtyWallUnit, setSixtyWallUnit] = useState("");
  const [builtUnderFreezer, setBuiltUnderFreezer] = useState("");
  const [integratedDishwasher, setIntegratedDishwasher] = useState("");
  const [integratedWasher, setIntegratedWasher] = useState("");
  const [integratedUtilityBoiler, setIntegratedUtilityBoiler] = useState("");

  //Upgrades
  const [triLights, setTriLights] = useState("");
  const [ledStripLighting, setLedStripLighting] = useState("");

  function IsFieldValid(field, errorMsg) {
    var valid = hasUserSelectedValue(field); 
    if (!valid)
      setFormError(errorMsg);
    return valid;
  }

  const verifyForm = () => {

    // Site and Plot Details
    if (!IsFieldValid(orderNumber,       "Please complete your order number.")) return;
    if (!IsFieldValid(region,            "Please complete your region.")) return;
    if (!IsFieldValid(deliveryDate,      "Please complete your delivery date.")) return;
    if (!IsFieldValid(site,              "Please complete your site.")) return;
    if (!IsFieldValid(siteContactName,   "Please complete your contact name.")) return;
    if (!IsFieldValid(siteEmail,         "Please complete your site email.")) return;
    if (!IsFieldValid(siteContactNumber, "Please complete your site contact number.")) return;
    if (!IsFieldValid(showHomePlot,      "Please complete the show home plot field.")) return;
    if (!IsFieldValid(plotNumber,        "Please complete your plot number.")) return;

    // Kitchen Details
    if (!IsFieldValid(kitchenType,   "Please complete your kitchen type.")) return;
    if (!IsFieldValid(houseType,     "Please complete your house type.")) return;
    if (!IsFieldValid(handling,      "Please complete the handling field.")) return;
    if (kitchenType === "PRIVATE")
      if (!IsFieldValid(standardUpgrade, "Please complete your range type.")) return;
    if (!IsFieldValid(range,         "Please complete your range.")) return;
    if (!IsFieldValid(handle,        "Please complete your handle type.")) return;
    if (!IsFieldValid(kitchenColour, "Please complete your kitchen colour.")) return;
    if (!IsFieldValid(worktopType,   "Please complete your worktop type.")) return;
    if (!IsFieldValid(worktopColour, "Please complete your worktop colour.")) return;

    // Appliances
    let upgradeRange = rangeSelected !== "BROMPTON";

    if (matchingPricingMatrix.zaupA1 >= 0)
      if (!IsFieldValid(tallOvenAndMicrowave, "Please complete the tall oven & microwave housing field.")) return;

    if (matchingPricingMatrix.zaupA2 >= 0)
      if (!IsFieldValid(sixtyWallUnit, "Please complete the 60cm wall unit microwave field.")) return;

    if (matchingPricingMatrix.zaupA3 >= 0)
      if (!IsFieldValid(builtUnderFreezer, "Please complete the built under integrated freezer field.")) return;

    if (matchingPricingMatrix.zaupA4A >= 0 || matchingPricingMatrix.zaupA4B >= 0)
      if (!IsFieldValid(integratedDishwasher, "Please complete the integrated dishwasher field.")) return;

    if ((!upgradeRange && matchingPricingMatrix.zaupA5A >= 0) || (upgradeRange && matchingPricingMatrix.zaupA5B >= 0))
      if (!IsFieldValid(integratedWasher, "Please complete the integrated washer field.")) return;

    if ((!upgradeRange && matchingPricingMatrix.zaupA6A >= 0) || (upgradeRange && matchingPricingMatrix.zaupA6B >= 0))
      if (!IsFieldValid(integratedUtilityBoiler, "Please complete the integrated utility boiler field.")) return;

    if (matchingPricingMatrix.zauaA1 >= 0)
      if (!IsFieldValid(ovenHobHoodPack, "Please complete the oven, hob & hood pack field.")) return;

    if (matchingPricingMatrix.zauaA2 >= 0)
      if (!IsFieldValid(integratedFridgeFreezer, "Please complete the integrated fridge freeezer field.")) return;

    // Upgrades
    if (matchingPricingMatrix.zaupU1 >= 0)
      if (!IsFieldValid(ledStripLighting, "Please complete the led strip lighting field.")) return;

    if (matchingPricingMatrix.zauaU1 >= 0)
      if (!IsFieldValid(triLights, "Please complete the tri-lights field.")) return;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        ZAMPON: orderNumber,
        ZAMRGN: region,
        ZAMRDD: deliveryDate,
        ZAMSITE: site,
        ZAMSNAME: siteContactName,
        ZAMSEML: siteEmail,
        ZAMSTEL: siteContactNumber,
        ZAMSHOW: showHomePlot,
        ZAMPLOT: plotNumber,
        ZAMKTYP: kitchenType,
        ZAMHTYP: houseType,
        ZAMHAND: handling,
        ZAMKSTY: standardUpgrade,
        ZAMKRAN: range,
        ZAMKHAN: handle,
        ZAMKCOL: kitchenColour,
        ZAMWTYP: worktopType,
        ZAMWCOL: worktopColour,
        ZAMAA1: ovenHobHoodPack,
        ZAMAA2: integratedFridgeFreezer,
        ZAMPA1: tallOvenAndMicrowave,
        ZAMPA2: sixtyWallUnit,
        ZAMPA3: builtUnderFreezer,
        ZAMPA4: integratedDishwasher,
        ZAMPA5: integratedWasher,
        ZAMPA6: integratedUtilityBoiler,
        ZAMAU1: triLights,
        ZAMPU1: ledStripLighting,
        ZAMDTS: new Date(),
        ZAMPRI: (currentPrice + upgradePrice).toFixed(2),
        ZAMREF: " ",
        ZAMTYP: props.loginInfo.accountType,
        ZAMMAS: props.loginInfo.accountNumber,
        ZAMTITL: props.loginInfo.title,
        ZAMNAME: siteContactName,
        ZAMTEL: siteContactNumber,
        ZAMMAL: siteEmail,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://prod-21.uksouth.logic.azure.com:443/workflows/d9e4e04fc07243de9387399343dac12a/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=-zuRNBh9L84YA7zaVXzemvXQB4Ha6bC3UhtmHqniZlg",
      requestOptions
    )
    .then((response) => {
        
      if (response.status === 409) {
        // Conflict - An order with the same housetype and plot number exists
        setFormError(
          "An order with the same housetype and plot number exists"
        );
        throw new Error("Conflict: The resource already exists.");
      }

      response.text();
    })
    .then((result) =>
      props.setComplete(true)
    )
    .catch((error) =>
      console.log("error", error)
    );

  };

  useEffect(() => {
    var getRequestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      "https://avantform.azurewebsites.net/api/GetRegions",
      getRequestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setRegions(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));

    fetch("https://avantform.azurewebsites.net/api/GetSites", getRequestOptions)
      .then((response) => response.text())
      .then((result) => {
        setSites(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));

    fetch(
      "https://avantform.azurewebsites.net/api/GetKitchenTypes",
      getRequestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setKitchenTypes(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));

    fetch(
      "https://avantform.azurewebsites.net/api/GetHouseTypes",
      getRequestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log("HT:", JSON.parse(result));
        setHouseTypes(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));

    fetch(
      "https://avantform.azurewebsites.net/api/GetRanges",
      getRequestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setRanges(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));

    fetch(
      "https://avantform.azurewebsites.net/api/GetHandles",
      getRequestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setHandles(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));

    fetch(
      "https://avantform.azurewebsites.net/api/GetKitchenColours",
      getRequestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setKitchenColours(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));

    fetch(
      "https://avantform.azurewebsites.net/api/GetWorktopTypes",
      getRequestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setWorktopTypes(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));

    fetch(
      "https://avantform.azurewebsites.net/api/GetWorktopColours",
      getRequestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setWorktopColours(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));

    fetch(
      "https://avantform.azurewebsites.net/api/GetPricingBase",
      getRequestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setPricingBase(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));

    fetch(
      "https://avantform.azurewebsites.net/api/GetPricingUpgrades",
      getRequestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setPricingUpgrades(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));

      fetch(
        "https://avantform.azurewebsites.net/api/GetPricingMatrix",
        getRequestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          setPricingMatrix(JSON.parse(result));
        })
        .catch((error) => console.log("error", error));
  }, []);

  let standardUpgradeCoponent = <></>;

  let appliances = [];
  let upgrades = [];

  let currentPrice = 0;
  let upgradePrice = 0;

  matchingPricingMatrix = null;

  if (kitchenType === "PRIVATE") {
    standardUpgradeCoponent = (
      <FormGroup>
        <Label for="standardUpgrade">Standard or Upgrade</Label>
        <Input
          value={standardUpgrade}
          onChange={(e) => setStandardUpgrade(e.target.value)}
          required
          type="select"
          name="standardUpgrade"
          id="standardUpgrade"
          placeholder="Standard or Upgrade"
        >
          <option>Please Select</option>
          <option>Standard</option>
          <option>Upgrade</option>
        </Input>
      </FormGroup>
    );
  } 
  
  matchingPricingMatrix = pricingMatrix.find(v => v.zauhtyp.trim() === houseType);
  let houseTypeSelected = hasUserSelectedValue(houseType);
  let kitchenTypeSelected = hasUserSelectedValue(kitchenType);
  let rangeSelected = false;
  if (houseTypeSelected && kitchenTypeSelected)
  {
    if (kitchenType === "PRIVATE") {
      if (hasUserSelectedValue(standardUpgrade))
        rangeSelected = hasUserSelectedValue(range);
    }
    else
      rangeSelected = hasUserSelectedValue(range);
  }

  upgradePrice = 0;
  if (houseTypeSelected && kitchenTypeSelected && matchingPricingMatrix !== undefined) {

    // Since the PricingMatrix table determines what appliances/upgrades are available 
    // Allow any option for any kitchen depending on the db result

    // APPLIANCES

    if (matchingPricingMatrix.zaupA1 >= 0) {
      appliances.push(
        <YesNoDropdown
          value={tallOvenAndMicrowave}
          name="tallOvenAndMicrowave"
          setValue={setTallOvenAndMicrowave}
          title="Tall Oven & Microwave Housing"
        />
      );
      if (tallOvenAndMicrowave === "Yes")
        upgradePrice += matchingPricingMatrix.zaupA1;
    }
    else if (tallOvenAndMicrowave !== "")
      setTallOvenAndMicrowave("");

    if (matchingPricingMatrix.zaupA2 >= 0) {
      appliances.push(
        <YesNoDropdown
          value={sixtyWallUnit}
          name="sixtyWallUnit"
          setValue={setSixtyWallUnit}
          title="60cm Wall Unit Microwave"
        />
      );
      if (sixtyWallUnit === "Yes")
        upgradePrice += matchingPricingMatrix.zaupA2;
    }
    else if (sixtyWallUnit !== "")
      setSixtyWallUnit("");

    if (matchingPricingMatrix.zaupA3 >= 0) {
      appliances.push(
        <YesNoDropdown
          value={builtUnderFreezer}
          name="builtUnderFreezer"
          setValue={setBuiltUnderFreezer}
          title="Built Under Integrated Freezer"
        />
      );
      if (builtUnderFreezer === "Yes")
        upgradePrice += matchingPricingMatrix.zaupA3;
    }
    else if (builtUnderFreezer !== "")
      setBuiltUnderFreezer("");

    if (matchingPricingMatrix.zaupA4A >= 0 || matchingPricingMatrix.zaupA4B >= 0) {
      appliances.push(
        <DishwasherDropdown
          value={integratedDishwasher}
          name="integratedDishwasher"
          setValue={setIntegratedDishwasher}
          title="Integrated Dishwasher"
          allow60cm={matchingPricingMatrix.zaupA4A >= 0}
          allow45cm={matchingPricingMatrix.zaupA4B >= 0}
        />
      );
      if (integratedDishwasher === "60cm-Full Size")
        upgradePrice += matchingPricingMatrix.zaupA4A;
      else if (integratedDishwasher === "45cm-Slim Line")
        upgradePrice += matchingPricingMatrix.zaupA4B;
    }
    else if (integratedDishwasher !== "")
      setIntegratedDishwasher("");

    // Below appliances depend on the range
    if (rangeSelected) {

      let upgradeRange = range !== "BROMPTON";

      if ((!upgradeRange && matchingPricingMatrix.zaupA5A >= 0) || 
      (upgradeRange && matchingPricingMatrix.zaupA5B >= 0)) {
        appliances.push(
          <YesNoDropdown
            value={integratedWasher}
            name="integratedWasher"
            setValue={setIntegratedWasher}
            title="Integrated Washer"
          />
        );
        if (integratedWasher === "Yes") {
          if (!upgradeRange)
            upgradePrice += matchingPricingMatrix.zaupA5A;
          else
            upgradePrice += matchingPricingMatrix.zaupA5B;
        }
      }
      else if (integratedWasher !== "")
        setIntegratedWasher("");

      // Integrated Washer/Dryer not included but would be here

      /*if ((!upgradeRange && matchingPricingMatrix.zaupA6A >= 0) || 
      (upgradeRange && matchingPricingMatrix.zaupA6B >= 0)) {
        appliances.push(
          <YesNoDropdown
            value={integratedUtilityBoiler}
            name="integratedUtilityBoiler"
            setValue={setIntegratedUtilityBoiler}
            title="Integrated Utility Boiler"
          />
        );
        if (integratedUtilityBoiler === "Yes") {
          if (!upgradeRange)
            upgradePrice += matchingPricingMatrix.zaupA6A;
          else
            upgradePrice += matchingPricingMatrix.zaupA6B;
        }
      }
      else if (integratedUtilityBoiler !== "")
        setIntegratedUtilityBoiler("");*/
    }

    if (matchingPricingMatrix.zauaA1 >= 0) {
      appliances.push(
        <YesNoDropdown
          value={ovenHobHoodPack}
          name="ovenHobHoodPack"
          setValue={setOvenHobHoodPack}
          title="Oven, Hob & Hood Pack"
        />
      );
      if (ovenHobHoodPack === "Yes")
        upgradePrice += matchingPricingMatrix.zauaA1;
    }
    else if (ovenHobHoodPack !== "")
      setOvenHobHoodPack("");

    if (matchingPricingMatrix.zauaA2 >= 0) {
      appliances.push(
        <YesNoDropdown
          value={integratedFridgeFreezer}
          name="integratedFridgeFreezer"
          setValue={setIntegratedFridgeFreezer}
          title="Integrated Fridge Freezer"
        />
      );
      if (integratedFridgeFreezer === "Yes")
        upgradePrice += matchingPricingMatrix.zauaA2;
    }
    else if (integratedFridgeFreezer !== "")
      setIntegratedFridgeFreezer("");

    // UPGRADES

    if (matchingPricingMatrix.zaupU1 >= 0) {
      upgrades.push(
        <YesNoDropdown
          value={ledStripLighting}
          name="ledStripLighting"
          setValue={setLedStripLighting}
          title="LED Strip Lighting"
        />
      );
      if (ledStripLighting === "Yes")
        upgradePrice += matchingPricingMatrix.zaupU1;
    }
    else if (ledStripLighting !== "")
      setLedStripLighting("");

    if (matchingPricingMatrix.zauaU1 >= 0) {
      upgrades.push(
        <YesNoDropdown
          value={triLights}
          name="triLights"
          setValue={setTriLights}
          title="Tri-Lights"
        />
      );
      if (triLights === "Yes")
        upgradePrice += matchingPricingMatrix.zauaU1;
    }
    else if (triLights !== "")
      setTriLights("");
  }

  // Set any appliance and upgrade cells unsuded to empty html
  while (appliances.length < 9) {
    appliances.push(<></>);
  }

  while (upgrades.length < 2) {
    upgrades.push(<></>);
  }

  if (houseTypeSelected && kitchenTypeSelected && rangeSelected)
  {
    let rgn = isRegionEngland(region) ? "ENGLAND" : region;
    let basePrice = pricingBase.find(p => p.zaP1RGN.trim() === rgn && p.zaP1TYP.trim() === houseType && p.zaP1RAN.trim() === range);
    if (basePrice != null)
      currentPrice = parseFloat(basePrice.zaP1TOT);
  }

  let worktopTypeElem = (
    <FormGroup>
      <Label for="worktopType">Worktop Type</Label>
      <Input
        value={worktopType}
        onChange={(e) => setWorktopType(e.target.value)}
        required
        type="select"
        name="worktopType"
        id="worktopType"
        placeholder="Worktop Type"
      >
        <option>Please Select</option>
        {worktopTypes.map((value) => {
          if (value.zaO8F1 === kitchenType && matchingPricingMatrix !== undefined) {

            if (value.zaO8OPT == "UPGRADE (KITCHEN ONLY)") {
              if (matchingPricingMatrix.zauuW1 != -0.01) {
                return <option key={value.zaO8OPT}>{value.zaO8OPT}</option>;
              }
            }
            else if (value.zaO8OPT == "UPGRADE (KITCHEN AND UTILITY)") {
              if (matchingPricingMatrix.zauuW2 != -0.01) {
                return <option key={value.zaO8OPT}>{value.zaO8OPT}</option>;
              }
            }
            else {
              return <option key={value.zaO8OPT}>{value.zaO8OPT}</option>;
            }
          }

          return <></>;
        })}
      </Input>
    </FormGroup>
  );
  if (matchingPricingMatrix) {
    if (worktopType == "UPGRADE (KITCHEN ONLY)")
      upgradePrice += matchingPricingMatrix.zauuW1;
    else if (worktopType == "UPGRADE (KITCHEN AND UTILITY)")
      upgradePrice += matchingPricingMatrix.zauuW2;
  }



  let displayPrice = `£${(currentPrice + upgradePrice).toFixed(2)}`;

  let submitForm = (
    <Container className="innerContainer">
      <Row>
        <Col md="12">
          <div>By clicking 'Place Order' you acknowledge that you are solely responsible for the accuracy of the information provided. 
JT Ellis cannot be held responsible for any errors or discrepancies.
Please review your order carefully before finalizing. Thank you.</div>
<br></br>
          <div className="text-danger">{formError}</div>
          <Button className="PlaceOrder" onClick={verifyForm}>Place Order</Button> <span className="TotalPrice">{displayPrice}</span>
        </Col>
      </Row>
    </Container>
  );

  return (
    <Form>
      <Container className="innerContainer">
        <Row>
          <Col md="3" className="subTitleBlock">
            Site and Plot Details
          </Col>
          <Col md="9"></Col>
        </Row>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label for="orderNumber">Avant Order Number</Label>
              <Input
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                required
                type="text"
                name="orderNumber"
                id="orderNumber"
                placeholder="Avant Order Number"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="region">Region</Label>
              <Input 
                value={region}
                required
                type="text"
                name="region"
                id="region"
                placeholder="Region"
                disabled />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="deliveryDate">W/C Delivery Date</Label>
              <DatePicker
                selected={deliveryDate}
                onChange={(date) => setDeliveryDate(date)}
                filterDate={isMonday}
                dateFormat="dd/MM/yyyy"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label for="site">Site</Label>
              <Input 
                value={site}
                required
                type="text"
                name="site"
                id="site"
                placeholder="Site"
                disabled />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="siteContactName">Site Contact Name</Label>
              <Input
                value={siteContactName}
                onChange={(e) => setSiteContactName(e.target.value)}
                required
                type="text"
                name="siteContactName"
                id="siteContactName"
                placeholder="Site Contact Name"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="orderNumber">Site Email</Label>
              <Input
                value={siteEmail}
                onChange={(e) => setSiteEmail(e.target.value)}
                required
                type="email"
                name="siteEmail"
                id="siteEmail"
                placeholder="Site Email"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="siteContactNumber">Site Contact Number</Label>
              <Input
                value={siteContactNumber}
                onChange={(e) => setSiteContactNumber(e.target.value)}
                required
                type="text"
                name="siteContactNumber"
                id="siteContactNumber"
                placeholder="Site Contact Number"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <YesNoDropdown
              value={showHomePlot}
              name="showHomePlot"
              setValue={setShowHomePlot}
              title="Show Home Plot"
            />
          </Col>
          <Col>
            <FormGroup>
              <Label for="plotNumber">Plot Number</Label>
              <Input
                value={plotNumber}
                onChange={(e) => setPlotNumber(e.target.value)}
                required
                type="number"
                name="plotNumber"
                id="plotNumber"
                placeholder="Plot Number"
              />
            </FormGroup>
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Container>

      <Container className="innerContainer">
        <Row>
          <Col md="3" className="subTitleBlock">
            Kitchen Details
          </Col>
          <Col md="9"></Col>
        </Row>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label for="kitchenType">Kitchen Type</Label>
              <Input
                value={kitchenType}
                onChange={(e) => { 
                  if (e.target.value !== kitchenType) {
                    setKitchenType(e.target.value);
                    setHouseType("Please Select");
                  }
                }}
                required
                type="select"
                name="kitchenType"
                id="kitchenType"
                placeholder="Kitchen Type"
              >
                <option>Please Select</option>
                {kitchenTypes.map((value) => (
                  <option key={value}>{value}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="houseType">House Type</Label>
              <Input
                value={houseType}
                onChange={(e) => setHouseType(e.target.value)}
                required
                type="select"
                name="houseType"
                id="houseType"
                placeholder="House Type"
              >
                <option>Please Select</option>
                {
                houseTypes.map((value, i) => {
                  if ((region==="SCOTLAND" && value.zaO4F2 === "SCOTLAND" && value.zaO4F1 === kitchenType) || (region!=="SCOTLAND" && value.zaO4F2 !== "SCOTLAND" && value.zaO4F1 === kitchenType)) {
                    return <option key={i}>{value.zaO4OPT}</option>;
                  } else {
                    return <></>;
                  }
                })}
              </Input>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="handling">Handing AS/OPP</Label>
              <Input
                value={handling}
                onChange={(e) => setHandling(e.target.value)}
                required
                type="select"
                name="handling"
                id="handling"
                placeholder="Handling"
              >
                <option>Please Select</option>
                <option>AS</option>
                <option>OPP</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md="3">{standardUpgradeCoponent}</Col>
        </Row>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label for="range">Range</Label>
              <Input
                value={range}
                onChange={(e) => {
                  handles.forEach((value) => {
                    if (value.zaO6F1 === e.target.value) {
                      setHandle(value.zaO6OPT);
                    }
                    setRange(e.target.value);
                  });
                }}
                required
                type="select"
                name="range"
                id="range"
                placeholder="Range"
              >
                <option>Please Select</option>
                {ranges.map((value) => {
                  if (value.zaO5F1 === kitchenType) {
                    if (
                      value.zaO5F2 === standardUpgrade ||
                      value.zaO5F2 === ""
                    ) {
                      return (
                        <option key={value.zaO5OPT}>{value.zaO5OPT}</option>
                      );
                    } else {
                      return <></>;
                    }
                  } else {
                    return <></>;
                  }
                })}
              </Input>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="handle">Handle</Label>
              <Input
                value={handle}
                onChange={(e) => setHandle(e.target.value)}
                readOnly
                type="text"
                name="handle"
                id="handle"
                placeholder="Handle"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="kitchenColour">Kitchen Colour</Label>
              <Input
                value={kitchenColour}
                onChange={(e) => setKitchenColour(e.target.value)}
                required
                type="select"
                name="kitchenColour"
                id="kitchenColour"
                placeholder="Kitchen Colour"
              >
                <option>Please Select</option>
                {kitchenColours.map((value) => {
                  if (value.zaO7F1 === range) {
                    return <option key={value.zaO7OPT}>{value.zaO7OPT}</option>;
                  } else {
                    return <></>;
                  }
                })}
              </Input>
            </FormGroup>
          </Col>
          <Col md="3"></Col>
        </Row>

        <Row>
          <Col md="3">
            {" "}
            {worktopTypeElem}
          </Col>
          <Col md="3">
            <FormGroup>
              <Label for="worktopColour">Worktop Colour</Label>
              <Input
                value={worktopColour}
                onChange={(e) => setWorktopColour(e.target.value)}
                required
                type="select"
                name="worktopColour"
                id="worktopColour"
                placeholder="Worktop Colour"
              >
                <option>Please Select</option>
                {worktopColours.map((value) => {
                  if (value.zaO9F1 === worktopType) {
                    return <option key={value.zaO9OPT}>{value.zaO9OPT}</option>;
                  } else {
                    return <></>;
                  }
                })}
              </Input>
            </FormGroup>
          </Col>
          <Col md="3"></Col>
          <Col md="3"></Col>
        </Row>
      </Container>

      <Container className="innerContainer">
        <Row>
          <Col md="3" className="subTitleBlock">
            Appliances
          </Col>
          <Col md="9" className="subTitleNoteBlock">
            Please Note: all appliances are supplied by Avant Homes
          </Col>
        </Row>
        <Row>
          <Col md="3">{appliances[0]}</Col>
          <Col md="3">{appliances[1]}</Col>
          <Col md="3">{appliances[2]}</Col>
          <Col md="3">{appliances[3]}</Col>
        </Row>
        <Row>
          <Col md="3">{appliances[4]}</Col>
          <Col md="3">{appliances[5]}</Col>
          <Col md="3">{appliances[6]}</Col>
          <Col md="3">{appliances[7]}</Col>
        </Row>
        <Row>
          <Col md="3">{appliances[8]}</Col>
          <Col md="3"></Col>
          <Col md="3"></Col>
          <Col md="3"></Col>
        </Row>
      </Container>

      <Container className="innerContainer">
        <Row>
          <Col md="3" className="subTitleBlock">
            Upgrades
          </Col>
          <Col md="9"></Col>
        </Row>
        <Row>
          <Col md="3">{upgrades[0]}</Col>
          <Col md="3">{upgrades[1]}</Col>
          <Col md="3"></Col>
          <Col md="3"></Col>
        </Row>
      </Container>

      {submitForm}
    </Form>
  );
}

// function isMonday(date) {
//   var now = new Date();
//   now.setDate(now.getDate() + 7 * 4);
//   now.setHours(0, 0, 0, 0);

//   if (date < now) {
//     return false;
//   } else {
//     const day = date.getDay();
//     return day === 1;
//   }
// }

function isMonday(date) {
  const today = new Date();
  const dayOfWeek = today.getDay();
  let weeksToAdd = 4;

  // If today is Thursday (4) to Sunday (0), add an extra week for 5th Monday
  if (dayOfWeek >= 4 || dayOfWeek === 0) {
    weeksToAdd = 5;
  }

  // Starting from the closest Monday to today
  let nextNthMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (1 - dayOfWeek + 7) % 7);

  // Adjust to 4th or 5th Monday from today
  nextNthMonday.setDate(nextNthMonday.getDate() + 7 * weeksToAdd);

  // Check if the date is a Monday and is at least the next 4th or 5th Monday from today
  const isDateMonday = date.getDay() === 1;
  const isAfterNthMonday = date >= nextNthMonday;

  return isDateMonday && isAfterNthMonday;
}

function hasUserSelectedValue(option) {
  return !(option === "" || option === "Please Select" || option === "Placeholder"); 
}

function isRegionEngland(region) {
  return region !== "Please Select" && region !== "" && region !== "SCOTLAND"; 
}

export default MainForm;
