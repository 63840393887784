import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "reactstrap";

import Header from "./Header";

const Layout =({children}) =>{
    return(
      <Container className="mainContainer">
        <Row>
            <Col>
            <Header />
            </Col>
        </Row>
        <Row>
          <Col><main>{children}</main></Col>
        </Row>
      </Container>
    )
}

export default Layout;