import { useState } from 'react';
import Layout from "./Components/Layout";
import Login from "./Components/Login";
import MainForm from "./Components/MainForm"; 
import Thanks from "./Components/Thanks"; 

import './App.css';

function App() {

  const [loginInfo, setLoginInfo] = useState({});
  const [complete, setComplete] = useState(false);

  let screen = (<Login loginInfo={loginInfo} setLoginInfo={setLoginInfo} />);

   if(loginInfo.key !== undefined ){
    if(complete){
     screen=(<Thanks />);
   } else {
    screen=(<MainForm loginInfo={loginInfo} setComplete={setComplete} />);
   }
  }
  return (
    <Layout>
      {screen}
    </Layout>
  );
}

export default App;
