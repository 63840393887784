import { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";

function Login(props) {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    let warningMessage = (<></>);

    if(props.loginInfo.status==="failed"){
      warningMessage=(<div className='warningMessage'>Invalid User</div>)
    }
    
  return (
    <Container className="innerContainer">
      <Row>
        <Col md="3" className="subTitleBlock">
          Login
        </Col>
        <Col md="9"></Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <FormGroup>
                <Label for="loginEmail">Email</Label>
                <Input value={userName} onChange={(e) => setUserName(e.target.value)} required type="email" name="email" id="loginEmail" placeholder="Email" />
            </FormGroup>
            <FormGroup>
                <Label for="loginPassword">Password</Label>
                <Input value={password} onChange={(e) => setPassword(e.target.value)} required type="password" name="password" id="loginPassword" placeholder="Password" />
            </FormGroup>
            {warningMessage}
            <FormGroup>
                <Button onClick={() => attemptLogin(userName, password, props.setLoginInfo)}>Login</Button>
            </FormGroup>
          </Form>
        </Col> 
      </Row>
    </Container>
  );
}

function attemptLogin(userName, password, setLoginInfo) {

  // Change to logic app for login and use same encrypt function

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var body = JSON.stringify({
      username: userName,
      password: password
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: body
    };
    
    fetch("https://prod-13.uksouth.logic.azure.com:443/workflows/fc05b1f0b04d406086ef327fd88568f5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=N1sbBxTsP-l5OvxD4zxunrz8FH5pzfMS2G4ZtkDp7ZM", requestOptions)
      .then(response => response.text())
      .then(result => {setLoginInfo(JSON.parse(result));})
      .catch(error => console.log('error', error));
}

export default Login;
